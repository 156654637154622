import React from 'react'
import { Link } from "react-router-dom";
import './slider.css'
import { motion } from 'framer-motion'

let imgUrls = [
    'https://res.cloudinary.com/dwdlnwmkn/image/upload/v1703170060/qn4vw2wmpjlu3gsoi7ku.png',
    'https://res.cloudinary.com/dwdlnwmkn/image/upload/v1703170093/f86rvctzwacd1vmcvixi.webp',
    'https://res.cloudinary.com/dwdlnwmkn/image/upload/v1703170131/calo878cdxznmrddeqrz.png',
    'https://res.cloudinary.com/dwdlnwmkn/image/upload/v1703170161/ps8ogeqajzp0s8lgvzmd.jpg',
    'https://res.cloudinary.com/dwdlnwmkn/image/upload/v1703170380/roovaayg6phjofvqrcgi.png',

 ]

const SliderProduct = () => {
  return (
    <div>
      <motion.div className='slider-container'>
        <motion.div className='slider' drag='x' 
        dragConstraints={{right: 0, left:-2123}} >
        {imgUrls.map(image => (
            <motion.div className='item'>
                <img src={image} alt="" />
            </motion.div>
        ))}
        </motion.div>
        
    </motion.div>
      <Link to ="/">
        <button as={Link} to='/' className='btn mx-auto capitalize'>Volver</button>
        </Link>
    </div>
  )
}

export default SliderProduct