import React from 'react'
import { Link } from "react-router-dom";
import './slider.css'
import { motion } from 'framer-motion'

let imgUrls = [
    'https://res.cloudinary.com/dwdlnwmkn/image/upload/v1687431570/b0bwyxaevpee4q6oexmw.jpg',
    'https://res.cloudinary.com/dwdlnwmkn/image/upload/v1692608877/svq2vbs7dvyqk2eyynis.jpg',
    'https://res.cloudinary.com/dwdlnwmkn/image/upload/v1703168704/mrh1xqdxxqyfebhalb1z.jpg',
    'https://res.cloudinary.com/dwdlnwmkn/image/upload/v1703168746/xedxyvevuo9gabeiojwq.jpg',
    'https://res.cloudinary.com/dwdlnwmkn/image/upload/v1703168792/usvrz8xurxequhoq6fau.jpg',

 ]

const Slider = () => {
  return (
    <div>
      <motion.div className='slider-container'>
        <motion.div className='slider' drag='x' 
        dragConstraints={{right: 0, left:-2123}} >
        {imgUrls.map(image => (
            <motion.div className='item'>
                <img src={image} alt="" />
            </motion.div>
        ))}
        </motion.div>
        
    </motion.div>
      <Link to ="/">
        <button as={Link} to='/' className='btn mx-auto capitalize'>Volver</button>
        </Link>
    </div>
  )
}

export default Slider