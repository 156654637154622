const SelectField = (props) => {
    const { label, handleChange, name } = props;
    return (
      <div className="mb-5">
        <label className="text-black text-sm" htmlFor={name}>{label}</label>
        <select onChange={handleChange} defaultValue="role" name={name} className="w-full border-b-2 py-2 outline-none">
          <option value="role" disabled>Elija asunto</option>
          <option value="Presupuesto" className="py-1">Presupuesto</option>
          <option value="Información" className="py-1">Información</option>
          <option value="Pregunta" className="py-1">Duda técnica</option>
        </select>
      </div>
    )
  }
  
  export default SelectField