import React from 'react'
import { Link } from "react-router-dom";

function About() {
    return (
        <div>
            <h1>En esta página hablamos sobre nuestra empresa y equipo de trabajo</h1>
            <Link to ="/">
        <button as={Link} to='/' className='btn mx-auto capitalize'>Volver</button>
        </Link>
        </div>
    )
}

export default About