import { Link } from "react-router-dom";
// import data
import { menuData } from '../data';
// import motion
import { motion } from 'framer-motion';
import Swal from 'sweetalert2'
// import variants
import { fadeIn, staggerContainer } from '../variants';
import React from 'react';

function Home() {
    // destructure menu data
  const { title, subtitle, modelImg, menuItems, btnText } = menuData;

 /* Swal.fire({
    imageUrl: "https://res.cloudinary.com/dwdlnwmkn/image/upload/v1703168102/cgpzu6et2eqbywa5xezn.jpg",
  });*/


  //Swal.fire('Aviso a nuestros clientes que nuestra oficina permancerá cerrada desde el día 23 de Diciembre hasta el día 7 de Enero por Vacaciones de Navidad. Tlf Averías urgentes 692 134 539, E-mail: atenciontecnica@solarfuer.com. El equipo de Solarfuer les desea... Felices Fiestas');
  return (
    <section className='min-h-[780px]'>
    {/* background */}
    <div className='h-[780px] bg-menu absolute w-full max-w-[1800px] -z-0'></div>
    {/* text */}
    <div className='relative z-10 top-72 lg:top-52'>
      <motion.div
        variants={staggerContainer}
        initial='hidden'
        whileInView={'show'}
        viewport={{ once: false, amount: 0.1 }}
        className='container mx-auto flex flex-col items-center text-center'
      >
        <motion.h2
          variants={fadeIn('down', 'tween', 0.2, 1.6)}
          className='h2 capitalize text-yellow-500 max-w-[800px] text-center'
        >
          {title}
        </motion.h2>
        <motion.p
          variants={fadeIn('down', 'tween', 0.4, 1.6)}
          className='text-white capitalize mb-8'
        >
          {subtitle}
        </motion.p>
        <motion.div variants={fadeIn('down', 'tween', 0.6, 1.6)}>
          <img src={modelImg} alt='' />
        </motion.div>
      </motion.div>
    </div>
    {/* menu gallery */}
    <motion.div
      initial={{
        opacity: 0,
        y: 350,
      }}
      whileInView={{
        opacity: 1,
        y: 0,
      }}
      transition={{
        type: 'tween',
        delay: 0.2,
        duration: 1.6,
        ease: 'easeOut',
      }}
      className='relative top-200 lg:top-96'
    >
      <div className='container mx-auto'>
        <div className='lg:-mt-24 min-h-[590px] z-30 mb-8 md:mb-4 xl:mb-16'>
          {/* grid */}
          <div className='grid grid-cols-1 lg:grid-cols-4 min-h-[590px]'>
            {menuItems.map((item, index) => {
              // destructure item
              const { image } = item;
              return (
                <div key={index}>
                  <div className='flex flex-row lg:flex-col h-full'>
                    {/* image */}
                    {/* reverse order for the first image and the last one */}
                    <div
                      className={`w-[45%] md:w-auto ${
                        index === 1 || index === 3
                          ? 'lg:order-1'
                          : 'order-none'
                      } `}
                    >
                      <img src={image} alt='' />
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
        <Link to ="/gallery">
        <button as={Link} to='gallery' className='btn mx-auto capitalize'>{btnText}</button>
        </Link>
      </div>
    </motion.div>
  </section>
  );
}

export default Home;