import React from 'react';
// import components
//import About from './components/About';
import Inicio from './components/inicio';
import { Routes, Route } from "react-router-dom"
import About from "./components/pages/about"
import ContactForm from "./components/pages/ContactForm"
import Contact from "./components/pages/contact"
import Productos from "./components/pages/productos"
import Gallery from "./components/pages/gallery"
import Service from "./components/pages/service"
function App() {
  return (
    <div className="App">
      <Routes>
        <Route path="/" element={ <Inicio/> } />
        <Route path="about" element={ <About/> } />
        <Route path="contact" element={ <Contact/> } />
        <Route path="products" element={ <Productos/> } />
        <Route path="gallery" element={ <Gallery/> } />
        <Route path="service" element={ <Service/> } />
        <Route path="contactform" element={ <ContactForm/> } />
      </Routes>
    </div>
  )
}

export default App
