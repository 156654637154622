const InputField = (props) => {
    const { handleChange, label, name, phone, type, value } = props;
    return (
      <div className="mb-5">
        <label className="text-black" htmlFor={name}>{label}</label>
        <input className="border-b-2 outline-none text-gray-700 w-full bg-white h-10 px-2" type={type} onChange={handleChange} value={value} name={name} phone ={phone} required />
      </div>
    )
  }
  
  export default InputField