import React, { Component } from 'react';
import Slider from './sliderImages';



class App extends Component {
  render() {
    return (
      <div >
         <Slider   /> 
         
       
       </div>
    );
  }
}

export default App;