import React, { Component } from 'react';
import SliderImagesProducts from './sliderImagesProducts';


class Productos extends Component {
    render() {
        return (
          <div >
             <SliderImagesProducts   /> 
             
           
           </div>
        );
      }
    }

export default Productos