import React from 'react'
import { Link } from "react-router-dom";

function Contact() {
    return (
        <div>
            <h1>Aquí te explicamos en que consisten nuestros servicios y que podemos ofrecerte</h1>
            <Link to ="/">
        <button as={Link} to='/' className='btn mx-auto capitalize'>Volver</button>
        </Link>
        </div>
    )
}

export default Contact