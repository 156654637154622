// import icons
import {
  FaFacebookF,
  FaInstagram,
} from 'react-icons/fa';
import React from 'react';
import { Link } from "react-router-dom";
// import data
//import { navData } from '../data';

const Nav = () => {
  return (
    <nav className='w-full h-full'>
      <ul className='h-full flex flex-col justify-center items-center gap-y-6'>
            <li>
                <a href = '/' className='text-xl capitalize font-primary italic hover:text-dark transition-all duration-300'>Inicio</a><br></br>
                <Link to ="/about">
                    <a href as={Link} to='/about' className='text-xl capitalize font-primary italic hover:text-dark transition-all duration-300'>Nosotros</a><br></br>
                </Link>
                <Link to ="/service" className='text-xl capitalize font-primary italic hover:text-dark transition-all duration-300'>
                    <a href as={Link} to='/service'>Servicios</a><br></br>
                </Link>
                <Link to ="/gallery" className='text-xl capitalize font-primary italic hover:text-dark transition-all duration-300'>
                    <a href as={Link} to='/gallery'>Galería</a><br></br>
                </Link>
                <Link to ="/contactform" className='text-xl capitalize font-primary italic hover:text-dark transition-all duration-300'>
                    <a href as={Link} to='/contactform'>Contacto</a><br></br>
                </Link>
                
              <a href = 'https://www.facebook.com/solarfuer/?locale=es_LA' className='text-xl capitalize font-primary italic hover:text-dark transition-all duration-300'><FaFacebookF /></a>
              <a href = 'https://instagram.com/solarfuer?igshid=YmMyMTA2M2Y=' className='text-xl capitalize font-primary italic hover:text-dark transition-all duration-300'><FaInstagram /></a>
            </li>
      </ul>
    </nav>
  );
};

export default Nav;