 import React from 'react';
// import components
import Hero from './Hero';
import About from './About';
import Home from './Home';
import Team from './Team';
import Testimonial from './Testimonial';
import Footer from './Footer';

const Inicio = () => {
  return (
    <div className='h-full bg-pattern bg-repeat max-w-[1800px] mx-auto overflow-hidden'>
      <Hero />
      <About />
      <Home />
      <Team />
      <Testimonial />
      <Footer />
    </div>
  );
};

export default Inicio;