//import './contactform.css';
import { useEffect, useState } from "react"
import InputField from "./InputField"
import SelectField from "./SelectField";
import TextareaField from "./TextareaField";
//import { ChevronRightIcon } from '@heroicons/react/solid'
import emailjs from 'emailjs-com';
import { Link } from "react-router-dom";
import Swal from 'sweetalert2'
import PostPhoto from '../../../src/assets/img/newsletter/bg-post.png';

const ContactForm = () => {
  const [values, setValues] = useState({
    fullName: '',
    email: '',
    phone: '',
    role: '',
    message: ''
  });
  const [status, setStatus] = useState('');

  const handleSubmit = (e) => {
    e.preventDefault();
 
    emailjs.send('service_9ks91lq', 'template_uvxpxoh', values, '5Vx56ffiOsOmPLe07')
      .then(response => {
        console.log('SUCCESS!', response);
        Swal.fire(
          'Consulta enviada correctamente,',
          'le responderemos a la mayor brevedad. Gracias',
          'success'
        );
        setValues({
          fullName: '',
          email: '',
          phone: '',
          role: '',
          message: ''
        });
        setStatus('SUCCESS');
        //alert('Consulta enviada correctamente, le responderemos a la mayor brevedad. Gracias');
        
      }, error => {
        console.log('FAILED...', error);
      });
  }

  useEffect(() => {
    if(status === 'SUCCESS') {
      setTimeout(() => {
        setStatus('');
      }, 3000);
    }
  }, [status]);

  const handleChange = (e) => {
    setValues(values => ({
      ...values,
      [e.target.name]: e.target.value
    }))
  }
  return (
    <div
    style={{
      textAlign: "center",
      maxWidth: "950px",
      margin: "0 auto",
      border: "1px solid #e6e6e6",
      padding: "40px 25px",
      marginTop: "50px",
      backgroundColor: "#fff",
    }}>
    
      <div>
        <img src={PostPhoto}style={{
            borderRadius: "25%",
            width: "600px",
            objectFit: "cover",
            marginBottom: "0"
          }} alt="Creando consulta"></img>  
         
      </div>
      <form onSubmit={handleSubmit} style={{
            width: "600px",
            objectFit: "cover",
            marginBottom: "0"
          }}>
             <h1 style={{
              lineHeight: 1.5,
              fontWeight: 300,
              marginBottom: "25px",
              fontSize: "5.375rem"
            }}>Díganos su consulta</h1>
        <InputField value={values.fullName} handleChange={handleChange} label="Nombre Completo" name="fullName" type="text" placeholder="John Doe" />
        <InputField value={values.email} handleChange={handleChange} label="E-Mail" name="email" type="email" placeholder="jphn@example.com" />
        <InputField value={values.phone} handleChange={handleChange} label="Teléfono" name="phone" type="text" placeholder="Teléfono" />
        <SelectField handleChange={handleChange} name="role" label="Asunto" />
        <TextareaField value={values.message} handleChange={handleChange} label="Escribe su consulta aquí" name="message" />
        <div>
        <button type="submit" className='btn mx-auto capitalize'>Enviar</button>
        <Link to ="/">
          <br></br>
        <button as={Link} to='/' className="btn mx-auto capitalize">Volver</button>
        </Link>
        </div>
      </form>
    </div>
  )
}

export default ContactForm