
// import icons
import {
  FaYoutube,
  FaFacebookF,
  FaInstagram,
  FaPinterestP,
  FaDiscord,
} from 'react-icons/fa';
// import images
import AboutImg from '../src/assets/img/about/plate.png';
import ModelWhiteImg from '../src/assets/img/model-white.png';
import ModelBlackImg from '../src/assets/img/model-black.png';
import MenuImg1 from '../src/assets/img/menu/1.png';
import MenuImg2 from '../src/assets/img/menu/2.png';
import MenuImg3 from '../src/assets/img/menu/3.png';
import MenuImg4 from '../src/assets/img/menu/4.png';
import MenuImg5 from '../src/assets/img/menu/5.png';
import MenuImg6 from '../src/assets/img/menu/6.png';
import MenuImg7 from '../src/assets/img/menu/7.png';
import MenuImg8 from '../src/assets/img/menu/8.png';
import SignatureImg from '../src/assets/img/team/signature.png';
import ChefImg from '../src/assets/img/team/chef.png';
import MapImg from '../src/assets/img/map/map.jpg';
import Avatar1 from '../src/assets/img/testimonial/avatar1.png';
import Avatar2 from '../src/assets/img/testimonial/avatar2.png';
import Avatar3 from '../src/assets/img/testimonial/avatar3.png';
import LogoMarca from '../src/assets/img/testimonial/logomarca.png';

export const navData = [
  //{ href: '/', name: 'Inicio' },
  //{ href: '/about', name: 'Nosotros' },
  //{ href: '/service', name: 'Servicios' },
  //{ href: '/gallery', name: 'Galeria' },
  //{ href: '/contact', name: 'Contacto' },
  //{ href: '/contactform', name: 'Contacto' },
  { href: 'https://www.facebook.com/solarfuer/?locale=es_LA', icon: <FaFacebookF /> },
  { href: 'https://instagram.com/solarfuer?igshid=YmMyMTA2M2Y=', icon: <FaInstagram /> },
];

export const heroData = {
  pretitle: 'Energía solar, Energía Renovable',
  title: 'SolarFuer',
  subtitle:
    '¿Estás buscando una empresa instaladora de paneles solares en Fuerteventura? No busques más, nuestra empresa puede ayudarte con resultados satisfactorios. Somos Solarfuer y te ofrecemos todo lo que puedas necesitar para  depender exclusivamente de la energía solar. Te explicamos cómo trabajamos. ',
  btnText: 'Más Info',
};

export const socialData = [
  { href: 'https://www.facebook.com/solarfuer/?locale=es_LA', icon: <FaFacebookF /> },
  { href: 'https://instagram.com/solarfuer?igshid=YmMyMTA2M2Y=', icon: <FaInstagram /> },
];

export const aboutData = {
  pretitle: 'Solarfuer',
  title: 'Sobre Nosotros',
  subtitle:'Si necesitas una empresa encargada de la reparación de instalaciones solares en Fuerteventura, ya puedes dejar de buscar. Somos Solarfuer y te ofrecemos un servicio integral para ayudarte a conseguir que tu domicilio o terreno obtenga una transición ecológica hacia energías mucho más sostenibles, como en este caso la fotovoltaica. ¿Quieres saber cómo trabajamos? Te lo explicamos a continuación.',
  btnText: 'Más Info',
  image: AboutImg,
};

export const menuData = {
  title: 'Instalaciones y Mantenimiento',
  subtitle: 'Aunque en general se trata de una energía muy segura, y los paneles, por lo regular, son totalmente fiables y muy resistentes, siempre pueden producirse daños o desperfectos de cualquier tipo. Este tipo de daños, evidentemente, tiene que ser arreglado por un profesional. Nosotros te ofrecemos nuestros servicios para corregir cualquier imperfección que pueda haber surgido o aparecer en un momento dado. Al trabajar también en la instalación, nuestra empresa utiliza y anima a sus clientes a emplear placas fotovoltaicas. Así, podemos ofrecerte packs con los mejores precios. Además, de esta forma podrás conseguir toda la eficacia energética posible. Precisamente, por eso último las reparaciones son tan importantes. Piensa que si tienes un panel o una placa estropeada, esta no va a producir energía, y tu suministro puede verse perjudicado. Por ello, es imprescindible que cuentes con una empresa de confianza a la que acudir ante este tipo de circunstancias. La energía solar, además, se está convirtiendo en una tendencia a nivel mundial, y todo apunta a que seguirá creciendo. Es la mejor forma de apostar por la autosuficiencia energética y por un consumo responsable y más amigable con el medio ambiente. En definitiva, si necesitas de la reparación de tus instalaciones fotovoltaicas, no lo dudes. Somos profesionales y te podemos ayudar a solventar cualquier tipo de problema o avería que haya surgido. Así pues, contáctanos ya mismo.',
  modelImg: ModelWhiteImg,
  btnText: 'Ver Más',
  menuItems: [
    {
      image: MenuImg1,
    },
    {
      image: MenuImg2,
    },
    {
      image: MenuImg3,
    },
    {
      image: MenuImg4,
    },
    {
      image: MenuImg5,
    },
    {
      image: MenuImg6,
    },
    {
      image: MenuImg7,
    },
    {
      image: MenuImg8,
    },
  ],
};

export const teamData = {
  pretitle: 'Ubicación',
  title: 'Poligono Industrial Llano de la Higuera',
  sub1: 'Lugar Llano de la Higuera 3, 929, Nave B3, El Cuchillete, Tuineje, Las Palmas, 35620',
  sub2: 'info@solarfuer.com/(+34)928 18 78 78',
  name: '',
  occupation: '',
  signatureImg: SignatureImg,
  chefImg: ChefImg,
  mapImg: MapImg
};

export const testimonialData = {
  title: "Nuestros Productos ",
  subtitle: 'Trabajamos con las Primeras Marcas',
  modelImg: LogoMarca,
  btnText: 'Ver Productos',
  slider: [
    {
      message:
        'Lorem ipsum dolor sit amet consectetur adipisicing elit. Odio quas ipsum eius voluptatibus. Quod ipsum ullam id facere a beatae incidunt eaque, veritatis architecto cum perferendis debitis tempora.',
      image: Avatar1,
      name: 'Rick Thompson',
      occupation: 'CEO of Delightful',
    },
    {
      message:
        'Lorem ipsum dolor sit amet consectetur adipisicing elit. Odio quas ipsum eius voluptatibus. Quod ipsum ullam id facere a beatae incidunt eaque, veritatis architecto cum perferendis debitis tempora.',
      image: Avatar2,
      name: 'John Doe',
      occupation: 'CEO of Delightful',
    },
    {
      message:
        'Lorem ipsum dolor sit amet consectetur adipisicing elit. Odio quas ipsum eius voluptatibus. Quod ipsum ullam id facere a beatae incidunt eaque, veritatis architecto cum perferendis debitis tempora.',
      image: Avatar3,
      name: 'Henry A.',
      occupation: 'CEO of Delightful',
    },
  ],
};

export const reservationData = {
  title: 'Contacto',
  subtitle:
    'Diganos fecha, hora y teléfono de contacto. Nos pondremos en contacto con usted lo antes posible, en horario de Lunes a Viernes de 8:30 a 16:30',
  modelImg: ModelBlackImg,
  btnText: 'Contactar',
};

export const newsletterData = {
  title: 'Únete a nuestro boletín',
  subtitle: 'Obtén las últimas ofertas y promociones en su e-mail',
  placeholder: 'Sigue al Sol',
  btnText: 'Subscribirse',
};

export const footerData = {
  contact: {
    title: 'Contacto',
    address: 'Lugar Llano de la Higuera 3, 929, Nave B3, El Cuchillete, Tuineje, Las Palmas, 35620',
    phone: '(+34)928187878/info@solarfuer.com',
  },
  hours: {
    title: 'Horario',
    program: [
      {
        days: 'Lunes - Viernes',
        hours: '8:30 AM - 16:00 PM',
      },
    ],
  },
  social: {
    title: 'Redes Sociales',
    icons: [
      { href: '/', icon: <FaYoutube /> },
      { href: '/', icon: <FaFacebookF /> },
      { href: '/', icon: <FaInstagram /> },
      { href: '/', icon: <FaPinterestP /> },
      { href: '/', icon: <FaDiscord /> },
    ],
  },
};
